import UsersList from "./../../components/usersList/UsersList";

import "./users.scss";

const Users = () => {
  return (
    <div className="list">
      <div className="listContainer">
        <UsersList />
      </div>
    </div>
  );
};

export default Users;
