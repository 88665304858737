import "./Banner.scss";
import Modal from "@mui/material/Modal";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import newRequest from "../../utils/newRequest";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [banners, setBanners] = useState([]);
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState(false);
  const [banner, setBanner] = useState(null);
  const [t] = useTranslation();

  useEffect(() => {
    const getBanners = async () => {
      try {
        setLoading(true);
        const res = await newRequest.get("/slider");
        setBanners(res?.data?.data?.images);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getBanners();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("images", image);
      setLoading(true);
      const res = await newRequest.put("/slider", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setBanners(res?.data?.data?.images);
      setLoading(false);
      setImage(null);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    const currentImages = [];
    currentImages.push(banner);
    try {
      setLoading(true);
      const res = await newRequest.put("/slider", {
        currentImages: currentImages,
      });
      setBanners(res?.data?.data?.images);
      setLoading(false);
      setConfirmDeleteMessage(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="banner">
      <div className="container">
        <div className="action-container">
          <button
            className="action-btn"
            onClick={() => setOpen(true)}
            style={{ minWidth: "120px" }}
          >
            {t("addBanner")}
          </button>
        </div>

        <div className="banners-container">
          {banners?.map((banner, index) => (
            <div className="single-banner" key={index}>
              <div className="banner-image">
                <img src={"https://resturant.asusapps.com" + banner} alt="" />
              </div>
              <div className="banner-footer">
                <div className="action-container">
                  <div
                    className="remove"
                    onClick={() => {
                      setConfirmDeleteMessage(true);
                      setBanner(banner);
                    }}
                  >
                    <DeleteIcon className="icon" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Adding Image Form Modal */}
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <form action="" className="form" onSubmit={handleSubmit}>
            <div className="form-control">
              <div className="test">
                <label htmlFor="image">
                  {t("chooseFile")}
                  <UploadIcon />
                </label>
                <span>{image?.name}</span>
              </div>
              <input
                type="file"
                id="image"
                placeholder={t("photo")}
                required
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>

            <div className="form-actions">
              <button
                className="action-btn"
                onClick={() => {
                  setOpen(false);
                  setImage(null);
                }}
              >
                {t("cancel")}
              </button>
              <button type="submit" className="action-btn">
                {t("add")}
              </button>
            </div>
          </form>
        </Modal>

        {/* Loading Modal */}
        <Modal
          open={loading}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="progress">
            <CircularProgress width={40} sx={{ color: "#ff611d" }} />
          </div>
        </Modal>

        {/* Modal For Delete Message */}
        <Modal
          open={confirmDeleteMessage}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="message-container">
            <div className="message">
              <div className="content">
                <p>{t("deleteConfirm")}</p>
              </div>
              <div className="action-container">
                <button
                  className="action-btn"
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  {t("ok")}
                </button>
                <button
                  className="action-btn-cancel"
                  onClick={() => {
                    setConfirmDeleteMessage(false);
                    setBanner(null);
                  }}
                >
                  {t("cancel")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Banner;
