import { useTranslation } from "react-i18next";
import React, { createContext, useState, useEffect } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [direction, setDirection] = useState(
    localStorage.getItem("direction") || "rtl"
  );
  const [t, i18n] = useTranslation();

  // Function to change the language
  const changeLanguage = (lang) => {
    if (lang === "SA") {
      setDirection("rtl");
      i18n.changeLanguage("ar");
    } else {
      setDirection("ltr");
      i18n.changeLanguage("en");
    }
    localStorage.setItem("direction", lang === "SA" ? "rtl" : "ltr");
  };

  // Load the initial direction from localStorage on component mount
  useEffect(() => {
    const storedDirection = localStorage.getItem("direction");
    if (storedDirection) {
      changeLanguage(storedDirection === "rtl" ? "SA" : "en");
    }
  }, []);

  // Values to be provided to the consumer components
  const values = {
    direction,
    changeLanguage,
  };

  return (
    <LanguageContext.Provider value={values}>
      {children}
    </LanguageContext.Provider>
  );
};
