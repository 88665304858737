import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StoreIcon from "@mui/icons-material/Store";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import PeopleIcon from "@mui/icons-material/People";
import BannerIcon from "@mui/icons-material/ImageOutlined";
import CategoryIcon from "@mui/icons-material/CategoryOutlined";
import OrderIcon from "@mui/icons-material/InsertChartOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import TableIcon from "@mui/icons-material/TableRestaurantOutlined";
import BillsIcon from "@mui/icons-material/ReceiptOutlined";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();
  const [t] = useTranslation();

  const handleClick = (item) => {
    setActiveItem(item.key);
  };

  const menuItems = [
    {
      key: "1",
      label: <span className="title">{t("home")}</span>,
      icon: <DashboardIcon className="icon" />,
      path: "/admin",
    },
    {
      key: "2",
      label: <span className="title">{t("employees")}</span>,
      icon: <PersonOutlineIcon className="icon" />,
      path: "/admin/employees",
    },
    {
      key: "3",
      label: <span className="label">{t("products")}</span>,
      icon: <StoreIcon className="icon" />,
      path: "/admin/products",
    },

    {
      key: "4",
      label: <span className="label">{t("sections")}</span>,
      icon: <CategoryIcon className="icon" />,
      path: "/admin/catalogs",
    },

    {
      key: "5",
      label: <span className="label">{t("orders")}</span>,
      icon: <OrderIcon className="icon" />,
      path: "/admin/orders",
    },

    {
      key: "6",
      label: <span className="label">{t("store")}</span>,
      icon: <DraftsOutlinedIcon className="icon" />,
      path: "/admin/stores",
    },

    {
      key: "7",
      label: <span className="label">{t("users")}</span>,
      icon: <PeopleIcon className="icon" />,
      path: "/admin/users",
    },

    // {
    //   key: "8",
    //   label: <span className="label">{t("tabels")}</span>,
    //   icon: <TableIcon className="icon" />,
    //   path: "/admin/tabels",
    // },

    {
      key: "9",
      label: <span className="label">{t("bills")}</span>,
      icon: <BillsIcon className="icon" />,
      path: "/admin/bills",
    },

    {
      key: "10",
      label: <span className="label">{t("banners")}</span>,
      icon: <BannerIcon className="icon" />,
      path: "/admin/banner",
    },
    {
      key: "11",
      label: <span className="label">{t("settings")}</span>,
      icon: <SettingsIcon className="icon" />,
      path: "/admin/settings",
    },
  ];

  return (
    <div className="sidebar">
      <div className="center">
        <ul className="menu">
          {menuItems.map((item) => (
            <Link
              key={item.key}
              to={item.path}
              className={`link ${
                activeItem === item.key || location.pathname === item.path
                  ? "active"
                  : ""
              }`}
              onClick={() => handleClick(item)}
            >
              {item.icon}
              {item.label}
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
