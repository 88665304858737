import "./tabels.scss";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/BorderColorOutlined";
import { useContext, useState } from "react";
import { Modal } from "@mui/material";
import DoneIcon from "@mui/icons-material/CheckCircleOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../context/LanguageContext";

const Tabels = () => {
  const [floor, setFloor] = useState("");
  const [fromNum, setFromNum] = useState("");
  const [toNum, setToNum] = useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSingle, setOpenSingle] = useState(false);
  const [addInSingle, setAddInSingle] = useState(false);
  const [editInSingle, setEditInSingle] = useState(false);
  const [t] = useTranslation();
  const [done, setDone] = useState(false);
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState(false);
  const { direction } = useContext(LanguageContext);

  const handleSubmit = async () => {};
  const handleEdit = async () => {};
  const handleDelete = async () => {};

  return (
    <>
      <div className="tabels">
        <div className="tabels-container">
          <div className="actions">
            <button className="action-btn" onClick={() => setOpen(true)}>
              {t("addTable")}
            </button>
          </div>
          <div className="wrapper">
            <div className="item">
              <div className="item-actions">
                <EditIcon className="icon" onClick={() => setOpenEdit(true)} />
                <DeleteIcon
                  className="icon"
                  onClick={() => setConfirmDeleteMessage(true)}
                />
              </div>
              <div className="item-header">
                <span>{t("floor")}</span>
                <span>{t("first")}</span>
              </div>
              <div className="item-body" onClick={() => setOpenSingle(true)}>
                <div className="body-line">
                  <span>{t("employees")}</span>
                  <span>20</span>
                </div>
                <div className="body-line">
                  <span>{t("tabels")}</span>
                  <span>10-20</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal For Add Tables*/}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* Add Table Form */}
        <div className="form-container">
          <form action="" onSubmit={handleSubmit}>
            <h3>{t("addTable")}</h3>
            <div className="control-group">
              <div className="form-control">
                <input
                  type="text"
                  placeholder={t("floor")}
                  required
                  minLength={1}
                  onChange={(e) => setFloor(e.target.value)}
                />
              </div>
            </div>
            <h3>{t("tabels")}</h3>
            <div className="control-group">
              <div className="form-control">
                <input
                  type="text"
                  placeholder={t("from")}
                  required
                  minLength={1}
                  onChange={(e) => setFloor(e.target.value)}
                />
              </div>
              <div className="form-control">
                <input
                  type="text"
                  placeholder={t("to")}
                  required
                  minLength={1}
                  onChange={(e) => setFloor(e.target.value)}
                />
              </div>
            </div>

            <div className="form-action">
              <button type="submit" className="action-btn">
                {t("add")}
              </button>
              <button
                type="submit"
                className="action-btn-cancel"
                onClick={() => setOpen(false)}
              >
                {t("cancel")}
              </button>
            </div>
          </form>

          {/* Modal  For Success Message */}
          <Modal
            open={done}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="message-container">
              <div className="message">
                <div className="content">
                  <DoneIcon className="icon " />
                  <p>{t("success")}</p>
                </div>
                <button
                  onClick={() => {
                    setOpen(false);
                    setDone(false);
                  }}
                >
                  {t("ok")}
                </button>
              </div>
            </div>
          </Modal>

          {/* Modal For Error Message */}
          {/* <Modal
            open={error}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="message-container">
              <div className="message">
                <div className="content">
                  <ErrorIcon className="icon " />
                  <p>لقد حدث خطاء ما برجاء المحاولة مرة اخري</p>
                </div>
                <button
                  onClick={() => {
                    setError(false);
                    // window.location.reload();
                  }}
                >
                  حسناً
                </button>
              </div>
            </div>
          </Modal> */}
        </div>
      </Modal>

      {/* Modal For Edit Tables*/}
      <Modal
        open={openEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* Edit Floor Form */}
        <div className="form-container">
          <form action="" onSubmit={handleSubmit}>
            <h3>{t("edit")}</h3>
            {/* <div className="control-group">
              <div className="form-control">
                <input
                  type="text"
                  placeholder="الاول"
                  required
                  minLength={4}
                  onChange={(e) => setFloor(e.target.value)}
                />
              </div>
            </div> */}
            <h3>{t("tabels")}</h3>
            <div className="control-group">
              <div className="form-control">
                <input
                  type="text"
                  placeholder="10"
                  required
                  minLength={1}
                  onChange={(e) => setFloor(e.target.value)}
                />
              </div>
              <div className="form-control">
                <input
                  type="text"
                  placeholder="20"
                  required
                  minLength={1}
                  onChange={(e) => setFloor(e.target.value)}
                />
              </div>
            </div>

            <div className="form-action">
              <button type="submit" className="action-btn">
                {t("edit")}
              </button>
              <button
                type="submit"
                className="action-btn-cancel"
                onClick={() => setOpenEdit(false)}
              >
                {t("cancel")}
              </button>
            </div>
          </form>

          {/* Modal  For Success Message */}
          <Modal
            open={done}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="message-container">
              <div className="message">
                <div className="content">
                  <DoneIcon className="icon " />
                  <p> {t("success")}</p>
                </div>
                <button
                  onClick={() => {
                    setOpen(false);
                    setDone(false);
                  }}
                >
                  {t("ok")}
                </button>
              </div>
            </div>
          </Modal>

          {/* Modal For Error Message */}
          {/* <Modal
            open={error}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="message-container">
              <div className="message">
                <div className="content">
                  <ErrorIcon className="icon " />
                  <p>لقد حدث خطاء ما برجاء المحاولة مرة اخري</p>
                </div>
                <button
                  onClick={() => {
                    setError(false);
                    // window.location.reload();
                  }}
                >
                  حسناً
                </button>
              </div>
            </div>
          </Modal> */}
        </div>
      </Modal>

      {/* Modal For Delete Message */}
      <Modal
        open={confirmDeleteMessage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="message-container">
          <div className="message">
            <div className="content">
              <p>{t("deleteConfirm")}</p>
            </div>
            <div className="action-container">
              <button
                className="action-btn"
                onClick={() => {
                  handleDelete();
                }}
              >
                {t("ok")}
              </button>
              <button
                className="action-btn-cancel"
                onClick={() => {
                  setConfirmDeleteMessage(false);
                }}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal For Open Single Floor */}
      <Modal open={openSingle} className="single-modal">
        <div className="single">
          <CloseIcon
            className="close-icon"
            onClick={() => setOpenSingle(false)}
          />
          <div className="singleContainer">
            <div className="single-header">
              <div className="item">
                <div className="item-body" style={{}}>
                  <div className="body-line">
                    <span>{t("floor")}</span>
                    <span>{t("first")}</span>
                  </div>
                  <div className="body-line">
                    <span>{t("tabels")}</span>
                    <span>10 - 20</span>
                  </div>
                </div>
              </div>

              <div className="actions">
                <button
                  className="action-btn"
                  onClick={() => setEditInSingle(true)}
                >
                  {t("edit")}
                </button>
                <button
                  className="action-btn"
                  onClick={() => setAddInSingle(true)}
                >
                  {t("add")}
                </button>
              </div>
            </div>

            <table className="styled-table">
              <thead>
                <tr>
                  <th>{t("waiter")}</th>
                  <th>{t("cleaner")}</th>
                  <th>{t("cashier")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
                <tr>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                  <td>محمد بن علي</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>

      {/* Modal For Add in Single Floor */}
      <Modal open={addInSingle}>
        <div className="form-container">
          <form action="">
            <div className="control-group" style={{ position: "relative" }}>
              <Select
                options=""
                className="react-selector"
                placeholder={t("waiter")}
                value=""
                isMulti={true}
              />
            </div>

            <div className="control-group" style={{ position: "relative" }}>
              <Select
                options=""
                className="react-selector"
                placeholder={t("cleaner")}
                value=""
                isMulti={true}
              />
            </div>

            <div className="control-group" style={{ position: "relative" }}>
              <Select
                options=""
                className="react-selector"
                placeholder={t("cashier")}
                value=""
                isMulti={true}
              />
            </div>

            <div className="form-action">
              <button type="submit" className="action-btn">
                {t("add")}
              </button>
              <button
                type="submit"
                className="action-btn-cancel"
                onClick={() => setAddInSingle(false)}
              >
                {t("cancel")}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* Modal For edit in Single Floor */}
      <Modal open={editInSingle}>
        <div className="form-container">
          <form action="">
            <div className="control-group" style={{ position: "relative" }}>
              <Select
                options=""
                className="react-selector"
                placeholder={t("waiter")}
                value=""
                isMulti={true}
              />
            </div>

            <div className="control-group" style={{ position: "relative" }}>
              <Select
                options=""
                className="react-selector"
                placeholder={t("cleaner")}
                value=""
                isMulti={true}
              />
            </div>

            <div className="control-group" style={{ position: "relative" }}>
              <Select
                options=""
                className="react-selector"
                placeholder={t("cashier")}
                value=""
                isMulti={true}
              />
            </div>

            <div className="form-action">
              <button type="submit" className="action-btn">
                {t("add")}
              </button>
              <button
                type="submit"
                className="action-btn-cancel"
                onClick={() => setEditInSingle(false)}
              >
                {t("cancel")}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Tabels;
