import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import newRequest from "../../utils/newRequest";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const List = () => {
  const [orders, setOrders] = useState([]);
  const [t] = useTranslation();

  useEffect(() => {
    const getOrders = async () => {
      try {
        const res = await newRequest.get("/order?limit=5");
        const data = await res?.data?.data;
        setOrders(data);
      } catch (error) {
        console.log(error);
      }
    };

    getOrders();
  }, []);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#FFF4F0" }}>
          <TableRow>
            <TableCell className="tableCell" align="center">
              {t("customer")}
            </TableCell>
            <TableCell className="tableCell" align="center">
              {t("date")}
            </TableCell>
            <TableCell className="tableCell" align="center">
              {t("total")}
            </TableCell>
            <TableCell className="tableCell" align="center">
              {t("method")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow key={row.id}>
              <TableCell className="tableCell" align="center">
                {row?.customer?.phone}
              </TableCell>
              <TableCell className="tableCell" align="center">
                {moment(row?.paidAt).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell className="tableCell" align="center">
                {row?.totalOrderPrice}
              </TableCell>
              <TableCell className="tableCell" align="center">
                {row?.paymentMethodType}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
