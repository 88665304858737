import { useEffect, useState } from "react";
import "./Bills.scss";
import newRequest from "../../utils/newRequest";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import Select from "react-select";
import moment from "moment";
import ErrorIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Modal } from "@mui/material";
import { DatePicker } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
const { RangePicker } = DatePicker;

const Bills = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1023px)");
  const [dates, setDates] = useState([]);
  const [tyeps, setTyeps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [bills, setBills] = useState([]);
  const [filterdBills, setFilterdBills] = useState([]);
  const [orderAnalysis, setOrderAnalysis] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [method, setMethod] = useState(null);
  const [total, setTotal] = useState(0);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [t] = useTranslation();
  const [clickedBill, setClickedBill] = useState(null);
  const direction = localStorage.getItem("direction");

  const handleRowClick = (params, event) => {
    setClickedBill(params.row);
    console.log(params.row);
  };

  useEffect(() => {
    const getOrders = async () => {
      setLoading(true);
      try {
        const res = await newRequest.get(`/invoice?limit=10&page=${page}`);
        const bills = await res?.data?.data;
        setBills(bills);
        setTotal(Math.floor(res?.data?.totalInvoicePrice));
        setTotalInvoices(res?.data?.totalInvoices);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getOrders();
  }, [page]);

  useEffect(() => {
    const getOrder = async () => {
      try {
        const res = await newRequest.get(
          `/analysis/${clickedBill?.orders[0].id}`
        );
        setOrderAnalysis(res?.data);
        console.log(res?.data);
      } catch (error) {
        console.log(error);
      }
    };

    clickedBill && getOrder();
  }, [clickedBill]);

  const handleFilter = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await newRequest.get(
        `/invoice?${method && `paymentMethod=${method.value}`}${
          from && `&from=${from}`
        }${to && `&to=${to}`}`
      );
      if (res?.data?.data.length === 0) {
        setError(true);
        setLoading(false);
        setDates([]);
        setMethod("");
        return;
      }
      setFilterdBills(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setDates(null);
      setLoading(false);
    }
  };

  const handleDateChange = (values) => {
    if (values) {
      const startDate = values[0].format("YYYY-MM-DD");
      const endDate = values[1].format("YYYY-MM-DD");
      setDates([startDate, endDate]);
      setFrom(startDate);
      setTo(endDate);
    } else {
      setDates(null);
    }
  };

  const handleOpenChange = (open) => {
    if (open && dates?.length === 2) {
      setDates([]);
    }
  };

  const options = [
    { value: "نقدى", label: "نقدى" },
    { value: "شبكة", label: "شبكة" },
    { value: "بطاقة ائتمانية", label: "بطاقة ائتمانية" },
  ];

  const billsColumns = [
    {
      field: "orderNum",
      headerName: t("orderNum"),
      width: isMobile ? 120 : isTablet ? 160 : 240,

      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params?.row?.orders && params?.row?.orders?.length > 0
          ? params?.row?.orders[0]?.orderNum
          : "",
    },
    {
      field: "paymentMethod",
      headerName: t("method"),
      width: isMobile ? 120 : isTablet ? 160 : 240,

      headerAlign: "center",
      align: "center",
    },
    {
      field: "invoiceNum",
      headerName: t("billNumber"),
      width: isMobile ? 120 : isTablet ? 160 : 240,

      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params?.row?.invoiceNum && params?.row?.invoiceNum?.length > 0
          ? params?.row?.invoiceNum
          : "",
    },
    {
      field: "totalPrice",
      headerName: t("price"),
      width: isMobile ? 120 : isTablet ? 160 : 240,

      headerAlign: "center",
      align: "center",
    },
    {
      field: "processNum",
      headerName: t("processNum"),
      width: isMobile ? 120 : isTablet ? 160 : 240,

      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <>
      <div className="datatable">
        <div className="wrapper">
          <Select
            options={options}
            className="react-selector"
            placeholder={t("method")}
            value={method}
            onChange={(e) => setMethod(e)}
          />

          <div className="date-picker">
            <RangePicker
              onChange={handleDateChange}
              onOpenChange={handleOpenChange}
              placeholder={[t("start"), t("end")]}
              value={
                dates?.length === 2
                  ? [moment(dates[0]), moment(dates[1])]
                  : null
              }
            />
          </div>
        </div>

        <div className="wrapper">
          <div className="item">
            <span>{t("orders")}</span>
            <span>{totalInvoices}</span>
          </div>

          <div className="item">
            <span>{t("total")}</span>
            <span>{total}</span>
          </div>
        </div>

        <div className="action-control">
          <button onClick={handleFilter}>{t("search")}</button>
          <button
            onClick={() => {
              setFilterdBills([]);
              setDates([]);
              setMethod("");
            }}
          >
            {t("cancel")}
          </button>
        </div>

        {/* For Display Data */}
        <DataGrid
          className="datagrid"
          sx={{ width: "100%", height: "85%" }}
          rows={filterdBills?.length > 0 ? filterdBills : bills}
          columns={billsColumns}
          pagination
          paginationMode="server"
          pageSize={pageSize}
          rowCount={
            filterdBills?.length > 0 ? filterdBills.length : totalInvoices
          }
          params={bills}
          page={page - 1}
          onPageChange={(newPage) => setPage(newPage + 1)}
          loading={loading}
          getRowId={(row) => row._id}
          onRowClick={handleRowClick}
          disableVirtualization
        />
      </div>

      {/* Modal For Error Message */}
      <Modal
        open={error}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="message-container">
          <div className="message">
            <div className="content">
              <ErrorIcon className="icon " />
              <p>{t("notFound")}</p>
            </div>
            <button
              onClick={() => {
                setError(false);
              }}
            >
              {t("ok")}
            </button>
          </div>
        </div>
      </Modal>

      {/* Modal For Display Single Order */}
      <Modal open={clickedBill} className="modal" sx={{ direction: direction }}>
        <div className="single-order">
          <div className="single-container">
            <div className="top">
              <div className="left">
                <div
                  className="icon-container"
                  onClick={() => setClickedBill(null)}
                >
                  <CloseIcon className="close-icon" />
                </div>
                <h1
                  className="title"
                  style={{
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {t("information")}
                </h1>
                <div className="item">
                  <div className="details">
                    <div className="detailItem">
                      <span className="itemKey">{t("customer")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {clickedBill?.orders[0]?.customer?.phone}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("date")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {moment(clickedBill?.createdAt).format("YYYY-MM-DD ")}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("total")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {clickedBill?.totalPrice}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("method")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {clickedBill?.paymentMethod}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("orderNum")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {clickedBill?.orders[0]?.orderNum}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("status")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {clickedBill?.isPaid
                          ? t("successPayment")
                          : t("failedPayment")}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("tabelNum")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {clickedBill?.orders[0]?.tableNum}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right">
                <h1
                  className="title"
                  style={{
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {t("orderStatus")}
                </h1>

                <div className="item">
                  <div className="details">
                    <div className="detailItem">
                      <span className="itemKey">{t("chef")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {Math.floor(orderAnalysis?.cheif?.cycleTime / 60000)}{" "}
                        {t("minute")}
                      </span>
                    </div>
                    <div className="detailItem">
                      <span className="itemKey">{t("waiter")}</span>
                      <span
                        className="itemValue"
                        style={{
                          color: "#FF611D",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {Math.floor(orderAnalysis?.waiter?.cycleTime / 60000)}{" "}
                        {t("minute")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom">
              <h1 className="title">{t("products")}</h1>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("products")}</th>
                    <th>{t("price")}</th>
                    <th>{t("quantity")}</th>
                    <th>{t("photo")}</th>
                  </tr>
                </thead>
                <tbody>
                  {clickedBill?.orders[0]?.cartItems?.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item?.product?.name}</td>
                      <td>{item?.product?.price}</td>
                      <td>{item?.quantity}</td>
                      <td>
                        <img
                          src={
                            "https://resturant.asusapps.com" +
                            item?.product?.image
                          }
                          alt=""
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Bills;
