// import { useEffect, useState } from "react";
// import "./chart.scss";
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   CartesianGrid,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";
// import newRequest from "../../utils/newRequest";
// import { useTranslation } from "react-i18next";

// const Chart = ({ aspect, title }) => {
//   const [t] = useTranslation();

//   useEffect(() => {}, []);
//   const months = [
//     t("january"),
//     t("february"),
//     t("march"),
//     t("april"),
//     t("may"),
//     t("june"),
//     t("july"),
//     t("august"),
//     t("september"),
//     t("october"),
//     t("november"),
//     t("december"),
//   ];

//   const [data, setData] = useState([
//     { name: t("january"), المجموع: 0 },
//     { name: t("february"), المجموع: 0 },
//     { name: t("march"), المجموع: 0 },
//     { name: t("april"), المجموع: 0 },
//     { name: t("may"), المجموع: 0 },
//     { name: t("june"), المجموع: 0 },
//     { name: t("july"), المجموع: 0 },
//     { name: t("august"), المجموع: 0 },
//     { name: t("september"), المجموع: 0 },
//     { name: t("october"), المجموع: 0 },
//     { name: t("november"), المجموع: 0 },
//     { name: t("december"), المجموع: 0 },
//   ]);

//   useEffect(() => {
//     const getData = async () => {
//       try {
//         const res = await newRequest.get("/analysis/month");
//         const updatedData = data.map((item) => {
//           const matchingObject = res?.data?.data.find((obj) => {
//             const monthIndex = obj.month - 1;
//             return months[monthIndex] === item.name;
//           });
//           return {
//             ...item,
//             المجموع: matchingObject ? matchingObject.totalPrice : 0,
//           };
//         });
//         setData(updatedData);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getData();
//   }, []);

//   return (
//     <div className="chart">
//       <div className="title">{title}</div>
//       <ResponsiveContainer width="100%" aspect={aspect}>
//         <AreaChart
//           width={"100%"}
//           height={250}
//           data={data}
//           margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
//         >
//           <defs>
//             <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
//               <stop offset="5%" stopColor="#FF611D" stopOpacity={0.8} />
//               <stop offset="95%" stopColor="#FF611D" stopOpacity={0} />
//             </linearGradient>
//           </defs>
//           <XAxis
//             dataKey="name"
//             stroke="gray"
//             className="name"
//             tickCount={12}
//             interval={0}
//           />
//           <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
//           <Tooltip />
//           <Area
//             type="monotone"
//             dataKey="المجموع"
//             stroke="#FF611D"
//             fillOpacity={1}
//             fill="url(#total)"
//           />
//         </AreaChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default Chart;

import { useEffect, useState } from "react";
import "./chart.scss";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import newRequest from "../../utils/newRequest";
import { useTranslation } from "react-i18next";

const Chart = ({ aspect, title }) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const months = [
      t("january"),
      t("february"),
      t("march"),
      t("april"),
      t("may"),
      t("june"),
      t("july"),
      t("august"),
      t("september"),
      t("october"),
      t("november"),
      t("december"),
    ];

    const initialData = months.map((month) => ({ name: month, المجموع: 0 }));
    setData(initialData);

    const getData = async () => {
      try {
        const res = await newRequest.get("/analysis/month");
        const updatedData = initialData.map((item) => {
          const matchingObject = res?.data?.data.find((obj) => {
            const monthIndex = obj.month - 1;
            return months[monthIndex] === item.name;
          });
          return {
            ...item,
            المجموع: matchingObject ? matchingObject.totalPrice : 0,
          };
        });
        setData(updatedData);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [t, i18n.language]);

  return (
    <div className="chart">
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart
          width={"100%"}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FF611D" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#FF611D" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="name"
            stroke="gray"
            className="name"
            tickCount={12}
            interval={0}
          />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="المجموع"
            stroke="#FF611D"
            fillOpacity={1}
            fill="url(#total)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
