import "./Catalogs.scss";

import CatalogList from "../../components/catalogsList/CatalogList";

const Catalogs = () => {
  return (
    <div className="list">
      <div className="listContainer">
        <CatalogList />
      </div>
    </div>
  );
};

export default Catalogs;
