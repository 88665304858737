import { Outlet } from "react-router-dom";
import "./Main.scss";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { useContext, useEffect, useState } from "react";
import newRequest from "../../utils/newRequest";
import { LanguageContext } from "../../context/LanguageContext";

const Main = (props) => {
  const [restaurant, setRestaurant] = useState(null);
  const { direction } = useContext(LanguageContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const restaurantRes = await newRequest.get("/profile");
        setRestaurant(restaurantRes?.data?.data[0]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="main">
      <Navbar restaurant={restaurant} />
      <div className="main-container">
        {props.toggleSideBar ? null : (
          <div className="main-menu">
            <Sidebar restaurant={restaurant} />
          </div>
        )}

        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Main;
