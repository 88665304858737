import "./order.scss";

import OrdersList from "../../components/ordersList/OrdersList";

const Orders = () => {
  return (
    <div className="list">
      <div className="listContainer">
        <OrdersList />
      </div>
    </div>
  );
};

export default Orders;
