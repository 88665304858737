import "./CatalogList.scss";
import newRequest from "../../utils/newRequest";
import Modal from "@mui/material/Modal";
import DoneIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorIcon from "@mui/icons-material/ErrorOutlineOutlined";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../context/LanguageContext";

const CatalogList = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1023px)");
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState(false);
  const [name, setName] = useState(null);
  const [catogryId, setCatogryId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [deletedCategory, setDeletedCategory] = useState(null);
  const [editCategory, setEditCategory] = useState(null);
  const { direction } = useContext(LanguageContext);
  const [t] = useTranslation();

  useEffect(() => {
    const Fetching = async () => {
      try {
        setLoading(true);
        const catogryRes = await newRequest.get("/catogry");
        setCategories(catogryRes?.data?.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    Fetching();
  }, [done]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await newRequest.post("/catogry", { name: name });
      console.log(res);
      setDone(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await newRequest.put(`/catogry/${editCategory.id}`, { name: name });
      setName(null);
      setDone(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await newRequest.delete(`/catogry/${deletedCategory.id}`);
      setCategories(
        categories.filter((item) => item.id !== deletedCategory.id)
      );
      setConfirmDeleteMessage(false);
      setDeletedCategory(null);
    } catch (error) {
      console.log(error);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: t("action"),
      width: isMobile ? 120 : isTablet ? 220 : 320,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              onClick={() => {
                setEditCategory(params.row);
                setOpenEditCategory(true);
              }}
              className="edit-button"
            >
              <div className="viewButton">{t("edit")}</div>
            </Link>
            <div
              className="delete-button"
              onClick={() => {
                setDeletedCategory(params.row);
                setConfirmDeleteMessage(true);
              }}
            >
              {t("delete")}
            </div>
          </div>
        );
      },
    },
  ];

  const categoryColumns = [
    {
      field: "name",
      headerName: t("name"),
      width: isMobile ? 120 : isTablet ? 220 : 320,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: t("date"),
      width: isMobile ? 120 : isTablet ? 220 : 320,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const formattedDate = moment(params.value).format("DD/MM/YYYY");
        return <div>{formattedDate}</div>;
      },
    },
  ];

  return (
    <>
      <div className="test">
        {/* Actions */}
        <div className="datatableTitle">
          <Link onClick={() => setOpenAddCategory(true)} className="add-button">
            {t("addCategory")}
          </Link>
        </div>

        {/* For Display Data */}
        <DataGrid
          className="datagrid"
          rows={categories}
          params={categories}
          columns={categoryColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          sx={{ height: 500 }}
          disableVirtualization
        />
        <Modal
          open={openAddCategory}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ direction: direction }}
        >
          <div className="form-container">
            {/* Add Product Form */}
            <form action="" onSubmit={handleSubmit} className="edit-form">
              <div className="control-group">
                <div className="form-control">
                  <input
                    type="text"
                    placeholder={t("name")}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-action">
                <button type="submit" className="action-btn">
                  {t("add")}
                </button>
                <button
                  type="submit"
                  className="action-btn-cancel"
                  onClick={() => setOpenAddCategory(false)}
                >
                  {t("cancel")}
                </button>
              </div>
            </form>

            {/* Modal  For Success Message */}
            <Modal
              open={done}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ direction: direction }}
            >
              <div className="message-container">
                <div className="message">
                  <div className="content">
                    <DoneIcon className="icon " />
                    <p>{t("success")}</p>
                  </div>
                  <button
                    onClick={() => {
                      setOpenAddCategory(false);
                      setDone(false);
                    }}
                  >
                    {t("ok")}
                  </button>
                </div>
              </div>
            </Modal>

            {/* Modal For Error Message */}
            <Modal
              open={error}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ direction: direction }}
            >
              <div className="message-container">
                <div className="message">
                  <div className="content">
                    <ErrorIcon className="icon " />
                    <p>{t("serverError")}</p>
                  </div>
                  <button
                    onClick={() => {
                      setError(false);
                    }}
                  >
                    {t("ok")}
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </Modal>
      </div>

      {/* For Open Add New Product */}

      {/* For Open Edit  Catalog */}
      <Modal
        open={openEditCategory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ direction: direction }}
      >
        <div className="form-container">
          {/* Edit Catalog Form */}
          <form action="" onSubmit={handleEdit} className="edit-form">
            <div className="control-group">
              <div className="form-control">
                <input
                  type="text"
                  placeholder={editCategory?.name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="form-action">
              <button type="submit" className="action-btn">
                {t("edit")}
              </button>
              <button
                type="submit"
                className="action-btn-cancel"
                onClick={() => setOpenEditCategory(false)}
              >
                {t("cancel")}
              </button>
            </div>
          </form>

          {/* Modal  For Success Message */}
          <Modal
            open={done}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ direction: direction }}
          >
            <div className="message-container">
              <div className="message">
                <div className="content">
                  <DoneIcon className="icon " />
                  <p>{t("editDone")}</p>
                </div>
                <button
                  onClick={() => {
                    setOpenEditCategory(false);
                    setDone(false);
                  }}
                >
                  {t("ok")}
                </button>
              </div>
            </div>
          </Modal>

          {/* Modal For Error Message */}
          <Modal
            open={error}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ direction: direction }}
          >
            <div className="message-container">
              <div className="message">
                <div className="content">
                  <ErrorIcon className="icon " />
                  <p>{t("serverError")}</p>
                </div>
                <button
                  onClick={() => {
                    setError(false);
                  }}
                >
                  {t("ok")}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </Modal>

      {/* Modal For Delete Message */}
      <Modal
        open={confirmDeleteMessage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ direction: direction }}
      >
        <div className="message-container">
          <div className="message">
            <div className="content">
              <p>{t("deleteConfirm")}</p>
            </div>
            <div className="action-container">
              <button
                className="action-btn"
                onClick={() => {
                  handleDelete();
                }}
              >
                {t("ok")}
              </button>
              <button
                className="action-btn-cancel"
                onClick={() => {
                  setConfirmDeleteMessage(false);
                }}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CatalogList;
