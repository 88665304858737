import "./home.scss";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import { useEffect, useState } from "react";
import newRequest, { setAuthHeader } from "../../utils/newRequest";
import CircularProgress from "@mui/material/CircularProgress";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useTranslation } from "react-i18next";
import Widget from "../../components/widget/Widget";

const Home = () => {
  const [orders, setOrders] = useState([]);
  const [restaurant, setRestaurant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [t] = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ordersRes = await newRequest.get(`/analysis/allorder`);
        const restaurantRes = await newRequest.get("/profile");
        setOrders(ordersRes?.data?.data);
        setRestaurant(restaurantRes?.data?.data[0]);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="loader"
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress className="circular-progress" size={40} />
        </div>
      ) : (
        <div className="home">
          <div className="homeContainer">
            <div className="widgets">
              <Widget
                ordersCount={orders?.customer}
                title={t("users")}
                icon={
                  <PersonOutlinedIcon
                    style={{
                      color: "#FF611D",
                    }}
                  />
                }
              />

              <Widget
                ordersCount={orders?.ordersCount}
                title={t("orders")}
                icon={
                  <ShoppingCartOutlinedIcon
                    style={{
                      color: "#FF611D",
                    }}
                  />
                }
              />

              <Widget
                ordersCount={Math.floor(orders?.totalOrders)}
                currency={restaurant?.currency}
                title={t("sales")}
                icon={
                  <MonetizationOnOutlinedIcon
                    style={{
                      color: "#FF611D",
                    }}
                  />
                }
              />
            </div>

            <div className="charts">
              <Chart title={t("revenue")} aspect={2 / 1} />
            </div>

            <div
              className="listContainer"
              style={{
                backgroundColor: "white",
              }}
            >
              <div className="listTitle">{t("lastFive")}</div>
              <Table />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
