import "./usersList.scss";
import { useEffect, useState } from "react";
import newRequest from "../../utils/newRequest";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Button, CircularProgress } from "@mui/material";
import moment from "moment";
import * as XLSX from "xlsx";

import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const UsersList = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [t] = useTranslation();

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res = await newRequest.get(`/customer`);
        const users = res?.data?.data || [];
        setUsers(users);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleExport = () => {
    const usersPhones = users.map((user) => ({
      Phone: user.phone,
      Orders: user.orderCount,
    }));

    const worksheet = XLSX.utils.json_to_sheet(usersPhones);

    const wscols = [
      { wch: 15 }, // Column width for the "Phone" label
      { wch: 20 }, // Column width for the phone value
      { wch: 12 }, // Column width for the "orderCount" label
    ];

    worksheet["!cols"] = wscols;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "users.xlsx");
  };

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{ justifyContent: "flex-end", backgroundColor: "#FFF4F0" }}
    >
      <Button
        onClick={handleExport}
        variant="fill"
        sx={{
          color: "#1976d2",
          fontWeight: 500,
          fontSize: "0.8125rem",
        }}
      >
        Export to Excel
      </Button>
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  const userColumns = [
    {
      field: "phone",
      headerName: t("customer"),
      width: 160,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: t("date"),
      type: "dateTime",
      align: "center",
      width: 160,
      headerAlign: "center",
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
    },

    {
      field: "orderCount",
      headerName: t("orders"),
      width: 160,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  return (
    <div className="datatable">
      {loading ? (
        <article className="loader">
          <CircularProgress className="circular-progress" size={40} />
        </article>
      ) : (
        <>
          <DataGrid
            sx={{ width: "97%", height: "85%" }}
            className="datagrid"
            rows={users}
            columns={userColumns}
            pageSize={9}
            rowsPerPageOptions={[9]}
            components={{
              Toolbar: CustomToolbar,
            }}
            disableVirtualization
          />
        </>
      )}
    </div>
  );
};

export default UsersList;
