import "./employees.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import newRequest from "../../utils/newRequest";
import EmployeesList from "../../components/employeesList/employeesList";

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getEmployees = async () => {
      try {
        const res = await newRequest.get("/stuff");
        setEmployees(res?.data?.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getEmployees();
  }, []);

  return (
    <div className="list">
      {loading ? (
        <article className="loader">
          <CircularProgress className="circular-progress" size={40} />
        </article>
      ) : (
        <EmployeesList employees={employees} />
      )}
    </div>
  );
};

export default Employees;
