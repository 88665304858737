import Axios from "axios";

export const url = "https://resturant.asusapps.com";

/// get stores
export const getAllStoresApi = (name) => {
  let result = Axios.get(`/api/v1/store?name=${name}`);
  return result;
};

/// add store
export const addStoreApi = (data) => {
  const formData = new FormData();
  Object.keys(data)?.forEach((key) => {
    formData.append(key, data[key]);
  });
  let result = Axios.post("/api/v1/store", formData);
  return result;
};

/// get store
export const getStoreApi = (id) => {
  let result = Axios.get(`/api/v1/store/${id}`);
  return result;
};

/// delete store
export const deleteStoreApi = (id) => {
  let result = Axios.delete(`/api/v1/store/${id}`);
  return result;
};

/// edit store

export const editStoreApi = (id, data) => {
  const formData = new FormData();
  Object.keys(data)?.forEach((key) => {
    formData.append(key, data[key]);
  });
  let result = Axios.put(`/api/v1/store/${id}`, formData);
  return result;
};

/// get products
export const getProductsApi = () => {
  let result = Axios.get("/api/v1/product");
  return result;
};

/// add new link
export const addNewLinkApi = (id, data) => {
  let result = Axios.patch(`/api/v1/store/${id}`, data);
  return result;
};

///delete linked product

export const deleteLinkedProductApi = (store, product) => {
  let result = Axios.delete(`/api/v1/store/${store}/product/${product}`);
  return result;
};

///edit linked product

export const editLinkedProductApi = (store, product, data) => {
  let result = Axios.put(`/api/v1/store/${store}/product/${product}`, data);
  return result;
};
