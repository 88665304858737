import "./login.scss";
import { useContext, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import newRequest from "../../utils/newRequest";
import ErrorIcon from "@mui/icons-material/ErrorOutlineOutlined";
import EyeIcon from "@mui/icons-material/Visibility";
import EyeSlashIcon from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import { LanguageContext } from "../../context/LanguageContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { changeLanguage } = useContext(LanguageContext);
  const Lang = localStorage.getItem("i18nextLng");
  const [t] = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);
    try {
      const res = await newRequest.post("/stuff/login", { email, password });
      localStorage.setItem("user", JSON.stringify(res.data.data));
      window.location.reload();
    } catch (error) {
      console.log(error.response.data.errors);
      setError(true);
      setLoading(false);
    }
  };

  const [visible, setVisiblity] = useState(false);
  const Icon = visible ? (
    <EyeSlashIcon
      className="second-icon"
      style={{ right: Lang === "en" && "10px", left: Lang === "ar" && "10px" }}
      onClick={() => {
        setVisiblity(!visible);
      }}
    />
  ) : (
    <EyeIcon
      className="second-icon"
      style={{ right: Lang === "en" && "10px", left: Lang === "ar" && "10px" }}
      onClick={() => {
        setVisiblity(!visible);
      }}
    />
  );

  const InputType = visible ? "text" : "password";

  return (
    <section className="login">
      <ReactFlagsSelect
        countries={["SA", "US"]}
        customLabels={{
          SA: "ar",
          US: "en",
        }}
        onSelect={(code) => {
          changeLanguage(code);
        }}
        placeholder="Select Language"
        selected={Lang === "en" ? "US" : "SA"}
        className="language"
      />
      <article className="container">
        <img
          src="https://res.cloudinary.com/dmymq7zql/image/upload/v1711371236/mlfg7ixfd6hahwfxd2h9.png"
          alt=""
        />
        <h1>{t("login")}</h1>
        <form action="" onSubmit={handleSubmit}>
          <div className="form-control">
            <input
              type="email"
              autoFocus
              placeholder={t("email")}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-control">
            <input
              required
              minLength={8}
              type={InputType}
              placeholder={t("password")}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {password && Icon}
          </div>

          {error && (
            <div className="error">
              <ErrorIcon style={{ color: "red" }} />
              <span style={{ color: "red" }}>{t("login-error")}</span>
            </div>
          )}
          <article className="form-action">
            {loading && (
              <CircularProgress className="circular-progress" size={30} />
            )}
            <button type="submit" className={loading ? "disabled" : ""}>
              {t("login")}
            </button>
          </article>
        </form>
      </article>
    </section>
  );
};

export default Login;
