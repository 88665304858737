import "./products.scss";
import ProductsList from "./../../components/productsList/ProductsList";

const Products = () => {
  return (
    <div className="list">
      <div className="listContainer">
        <ProductsList />
      </div>
    </div>
  );
};

export default Products;
